import USERPRODUCTVIEW_ACTION_TYPES from "./userProductView.types";
export const USERPRODUCTVIEW_INITIAL_STATE = {
  userProductViewLists: [], 
};

export const UserProductViewReducer = (state = USERPRODUCTVIEW_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case USERPRODUCTVIEW_ACTION_TYPES.LOAD_USERPRODUCTVIEWS: {
      return { ...state, userProductViewLists: payload };
    }
    default:
      return state;
  }
};
