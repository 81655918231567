import { Avatar, Box, Button, IconButton, styled } from "@mui/material";
import useAlert from "hooks/useAlert";
import useSnackbar from "hooks/useSnackbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Datatable from "ui-component/tables/Datatable";
import { capitalizeFirstLetter, isObjectEmpty } from "utils/helper";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { productDelete, productList, productUpdateStatus } from "services/api/product";
import { setProductList } from "store/product/product.action";
import { selectProduct } from "store/product/product.selector";
import Breadcrumb from "ui-component/Breadcrumb";
 
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
        marginBottom: "30px",
        [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
}));


const Product = () => {

    const title = 'product';

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { updateState } = useAlert();

    const { openSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);

        try {
            productList().then((res) => {
                // console.log('prdcts', res.data.result.data);
                dispatch(setProductList(res.data.result.data));
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    }, [])

    const deleteHandler = (id) => {
        try {
            productDelete(id).then((res) => {
                productList().then((res) => {
                    dispatch(setProductList(res.data.result.data));
                });
                openSnackbar("success", res.data.message)
            }).catch((error) => {
                openSnackbar("error", error.data.message)
            });
        } catch (e) {
            // console.log(e)
        }
    }

    const openNewTab = (url) => {
        // Replace 'https://example.com' with the URL you want to open in a new tab
        console.log(url);
        // Open the URL in a new tab
        window.open(url, '_blank');
    };

    const handleEdit = (event, row) => {
        const url = '/products/edit/' + row.rowData[0];
        navigate(url);

    }

    const handleStatusUpdate = (id, value) => {
        productUpdateStatus(id).then((res) => {
            productList().then((res) => {
                dispatch(setProductList(res.data.result.data));
            });
            openSnackbar("success", res.data.message)
        }).catch((error) => {
            openSnackbar("error", error.data.message)
        });
    }

    const handleDelete = (event, row) => {
        updateState('Product Delete', () => deleteHandler(row['rowData'][0]));
    }

    const handleView=(event,row)=>{
        const url='/products/view/'+row.rowData[0];
        navigate(url);
    }
    const products = useSelector(selectProduct);

    const columns = [
        {
            name: "id",
            label: "id",
            options: {
                display: false,
                download: false,
                filter: false,
                sort: false,
            }
        },
        {
            name: "Sr.no.",
            options: {
                display: true,
                download: true,
                filter: false,
                sort: false,
            }
        },
        {
            name: "product_image",
            label: "Image",
            options: {
                customBodyRender: (valueData, tableMeta) => {
                    return (
                        <Avatar
                            alt="Category image"
                            src={valueData}
                            variant="square"
                            sx={{ width: 50, height: 50 }}
                        />);
                },
                filter: false,
            }
        },
        {
            name: "english_title",
            label: "Title In English",
        },
        {
            name: "arabic_title",
            label: "Title In Arabic"
        },
        {
            name: "category_id",
            label: "Category",
            options: {
                filter: false,
            }
        },
        {
            label: "View AR ",
            options: {
                filter: false,
                customBodyRender: (valueData, tableMeta, updateValue) => {
                    console.log(valueData)
                    return (
                        <>
                            <Button onClick={() => openNewTab(valueData)} variant="outlined">view AR</Button>

                        </>
                    );
                }
            }
        },
        {
            name: "Actions",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                           <IconButton onClick={(e) => { handleView(e, tableMeta)}}>
                                <RemoveRedEyeIcon color="primary" />
                            </IconButton>
                            <IconButton onClick={(e) => { handleEdit(e, tableMeta) }}>
                                <EditIcon color="primary" />
                            </IconButton>
                            <IconButton onClick={(e) => { handleDelete(e, tableMeta) }}>
                                <DeleteIcon color="danger" />
                            </IconButton>
                        </>
                    );
                }
            }
        }
    ];

    const data = isObjectEmpty(products) ? [] : products.map((item, index) => {
        console.log(item);
        return [
            item.id,
            index += 1,
            item.colors[0].file_2d,
            item.english_title,
            item.arabic_title,
            item.category[0].english_name,
            // (item.is_active == 1) ? 'Active' : 'Inactive',
            item.colors[0].file_3d,
        ]
    })


    const options = {
        filterType: 'textField',
        fixedHeader: true,
        tableBodyHeight: "400px"
    }

    return (
        // <Container>
        //     <Box className="breadcrumb">
        //         <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Product Create" }]} />
        //     </Box>
            <Datatable title={title} data={data} columns={columns} options={options} isLoading={loading} addHandler={() => navigate("/products/create")} />
        // </Container>
    );
};

export default Product;
