const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    baseUrl: 'https://api-alghanimalbahar.tech-and-beyond.com/',
    basename: '/localhost',
    defaultPath: '/session/signin',
    fontFamily: `'Poppins', sans-serif`,
    borderRadius: 16,
    projectName: 'Alghanim Albahar',
    projectWebsite: 'alshabi.tech-and-beyond.com',
    companyName: 'Leza Solutions',
    companyWebsite: 'http://lezasolutions.com/',
};

export default config;
