import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helper";
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectCategory, selectSubCategory } from "store/category/category.selector";
import { categoryUpdate } from "services/api/category";
import SubCategoryForm from "./SubCategoryForm";

const SubCategoryUpdate = () => {
    const title = 'sub category';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    const dispatch = useDispatch();
    const { subCategoryId } = useParams();

    const subCategoryState = useSelector(selectSubCategory);

    const currentSubCategory = subCategoryState.filter(item => item.id == subCategoryId);

    const handleSubmit = (state) => {

        const formData = new FormData();
        formData.append("arabic_name", state.arabic_name)
        formData.append("english_name", state.english_name)
        formData.append("parent_id", state.parent_id)

        if (state.arabic_image instanceof File) {
            formData.append("arabic_image", state.arabic_image)
        }
        if (state.english_image instanceof File) {
            formData.append("english_image", state.english_image)
        }
        return categoryUpdate(subCategoryId, formData);
    };

    return (
        <Container>
            <Box className="breadcrumb">
                <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Sub Category Update" }]} />
            </Box>
            <SimpleCard title="Sub Category Form">
                <SubCategoryForm handleSubmit={handleSubmit} tableData={{ ...currentSubCategory[0] }} />
            </SimpleCard>
        </Container>
    );
};

export default SubCategoryUpdate;
