import { createSelector } from "reselect";

const selectUserProductViewReducer = (state) => {  
  return state.userProductView.userProductViewLists
};

export const selectUserProductView = createSelector(
  [selectUserProductViewReducer],
  (state) => {
    return state
  }
);