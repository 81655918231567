import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ProductForm from "./ProductForm";
import { capitalizeFirstLetter } from "utils/helper"; 
import Breadcrumb from "ui-component/Breadcrumb";
import SimpleCard from "ui-component/SimpleCard";
import { selectProduct } from "store/product/product.selector";
import { productUpdate } from "services/api/product";

const ProductUpdate = () => {
  const title = "product";

  const Container = styled("div")(({ theme }) => ({
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }));

  const dispatch = useDispatch();
  const { productId } = useParams();

  const productState = useSelector(selectProduct);
 
  let currentProduct = productState.filter((item) => item.id == productId);
  currentProduct[0]={...currentProduct[0],category:currentProduct[0].category[0]} 
  currentProduct[0]={...currentProduct[0],category_id:currentProduct[0].category['id']} 
  currentProduct[0]={...currentProduct[0],color:currentProduct[0].colors[0]['english_name']}
  currentProduct[0]={...currentProduct[0],image:currentProduct[0].colors[0]['file_2d']}
  currentProduct[0]={...currentProduct[0],file:currentProduct[0].colors[0]['file_3d']}

  // currentProduct={...currentProduct,category:currentProduct[0].category[0]['arabic_name']}
  const handleSubmit = (state, color) => {
    console.log('form submit')
    console.log(state)
    console.log(color)
    const formData = new FormData();
    formData.append("english_title", state.english_title)
    formData.append("arabic_title", state.arabic_title)
    formData.append("english_desc", state.english_desc)
    formData.append("arabic_desc", state.arabic_desc)
    formData.append("category_id", state.category_id)
    color.map((item, index) => {
        formData.append(`color[${index}][id]`, item.color_id)
        formData.append(`color[${index}][file][2d][0]`, item.image)
        formData.append(`color[${index}][file][3d][0]`, item.file)
    })
    
    return productUpdate(productId,formData);
  }; 
  
  return (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter(title), path: "/" + title },
            { name: "Product Update" },
          ]}
        />
      </Box>
      <SimpleCard title="Product Form">
        <ProductForm
          handleSubmit={handleSubmit}
          tableData={{ ...currentProduct[0] }}
        />
      </SimpleCard>
    </Container>
  );
};

export default ProductUpdate;
