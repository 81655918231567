
import { styled,Button } from "@mui/material"; 

import Breadcrumb from "ui-component/Breadcrumb";
import { capitalizeFirstLetter } from "utils/helper"; 
import * as React from 'react'; 
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { selectProduct } from "store/product/product.selector";

import Avatar from '@mui/material/Avatar';
import MainCard from "ui-component/cards/MainCard";
const ProductView=()=>{

    const title = 'products';

    const Container = styled("div")(({ theme }) => ({
        margin: "30px",
        [theme.breakpoints.down("sm")]: { margin: "16px" },
        "& .breadcrumb": {
            marginBottom: "30px",
            [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
        },
    }));

    
    const openNewTab = (url) => {
            console.log(url); 
        window.open(url, '_blank');
    };

    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body4,
        padding: theme.spacing(1),
        textAlign: 'left',
        fontSize:'18px',
        color: theme.palette.text.secondary,
      }));

      
  const { productId } = useParams();
  const productState = useSelector(selectProduct);
  const product = productState.filter((item) => item.id == productId);
 
 
  const data = product[0]['colors']; 
return (  
<Container>
    <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: capitalizeFirstLetter(title), path: "/" + title }, { name: "Product View" }]} />
    </Box>


            <MainCard title=""  >      
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 5 }} sx={{marginInline: 2 }} >
                    <Grid xs={6} sx={{ mt:2 }}>
                        <h2>Product</h2>
                      <Item>English Title :- {product[0]['english_title']}</Item>
                      <Item>Arabic Title :- {product[0]['arabic_title']}</Item>
                      <Item>English Desc :- {product[0]['english_desc']}</Item>
                      <Item>Arabic Desc :- {product[0]['arabic_desc']}</Item>
                      <Item>Category :- {product[0]['category'][0]['english_name']}</Item>
                    </Grid>

                  
                   
                    {data.map((item)=>{ 
                        return(   
                            <Grid xs={6} sx={{ mt:2 }}>
                                <h2>Color</h2>
                                    <Item>Arabic Name :- {item.arabic_name} </Item>  
                                    <Item>English Name :- {item.english_name} </Item>  
 
                                    <Item style={{ justifyContent: "left", display: "flex" }}> 
                                    <Item > 
                                       2d:- <br/>
                                    </Item>
                                    <Avatar  variant="square" src={item.file_2d}></Avatar>
                                    </Item>
                                    <Item style={{ justifyContent: "left", display: "flex" }}> 
                                    <Item> 
                                       3d:- 
                                    </Item>
                                    <Button onClick={() => openNewTab(item.file_3d)}   variant="outlined">view AR</Button>

                                       </Item>
                                </Grid> 
                        )
                    })}
                  
                </Grid>
            </MainCard>
</Container>)
}

export default ProductView;