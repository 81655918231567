 
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
// ==============================|| JOB WORK MENU ITEMS ||============================== //

const userProductView = {
    id: 'userProductView',
    title: 'Request',
    type: 'group',
    children: [
        {
            id: 'userProductView',
            title: 'User Product Reqests',
            type: 'item',
            url: '/user-product-request',
            icon: ContactEmergencyIcon,
            breadcrumbs: false
        },
                
    ]
};

export default userProductView;
