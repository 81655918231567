 import AuthGuard from "auth/AuthGuard"
 import MainLayout from "layout/MainLayout"
 import UserProductView from "./UserProductView"

const UserProductViewRoute={
    path:'/',
    element:<AuthGuard><MainLayout/></AuthGuard>,
    children:[
        {
            path:'/user-product-request',
            element:<UserProductView/>
        }
    ]
}

export default UserProductViewRoute;