import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'auth/AuthGuard';
import Admin from 'views/admin/Admin';
import Product from './Product';
import ProductCreate from './ProductCreate';
import ProductView from './ProductView';
import ProductUpdate from './ProductUpdate';



// ==============================|| MAIN ROUTING ||============================== //

const ProductRoutes = {
    path: '/',
    element: <AuthGuard> <MainLayout /> </AuthGuard>,
    children: [
        {
            path: '/products',
            element: <Product/>
        },
        {
            path: '/products/create',
            element: <ProductCreate/>
        },
        { 
          path: '/products/view/:productId', 
          element: <ProductView />
        },
        { 
          path: '/products/edit/:productId', 
          element: <ProductUpdate />
        },

        
    ]
};

export default ProductRoutes;
