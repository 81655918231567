import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { Formik } from "formik";
import useSnackbar from "hooks/useSnackbar";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectSubCategory } from "store/category/category.selector";
import { selectColor } from "store/color/color.selector";
import FileInput from "ui-component/FileInput";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from "@mui/system";
import SubCard from "ui-component/cards/SubCard";
import { uploadFile } from "services/api/file";



const ProductForm = ({ handleSubmit, tableData }) => {
    const [state, setState] = useState({ ...tableData });
    const [colorField, setColorField] = useState([{ color_id: state?.colors?.[0]?.color_id??"", image: state?.colors?.[0]?.file_2d_id??"", file: state?.colors?.[0]?.file_3d_id??"" }]);

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { openSnackbar } = useSnackbar();

    const subCategories = useSelector(selectSubCategory);
    const color = useSelector(selectColor);
    const newColor=color.filter(function(item){
        return state.color==item.english_name
    })

    const handleChange = (event) => {
        event.persist();
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const handleFileChange = (index, file) => {
        console.log(index, file.files[0]);

        const formData = new FormData();
        formData.append("type_id", 1)
        formData.append("file", file.files[0])

        uploadFile(formData).then((res) => {
            console.log(res);
            const updatedColorField = [...colorField];
            updatedColorField[index].image = res.data.result.id;
            setColorField(updatedColorField);
        }).catch((e) => {
            console.log(e)
        });


        console.log(colorField);
    };

    const handleColorChange = (index, value) => {
        console.log(value.id);
        const updatedColorField = [...colorField];
        updatedColorField[index].color_id = value.id;
        setColorField(updatedColorField);
        console.log(colorField);
    };

    const handleAddField = () => {
        setColorField([...colorField, { color_id: "", image: "", file: "" }])
    }

    const handleRemoveField = (index) => {
        setColorField(colorField.filter((_, i) => i !== index));
    };

    const handleFileUpload = (index, file) => {

        setLoading(true);
        const formData = new FormData();
        formData.append("type_id", 2)
        formData.append("file", file)

        uploadFile(formData).then((res) => {
            console.log(res);
            const updatedColorField = [...colorField];
            updatedColorField[index].file = res.data.result.id;
            setColorField(updatedColorField);
        }).catch((e) => {
            console.log(e)
        });
        setLoading(false);
        console.log(colorField);
    };

    const handleFormSubmit = async (values, { setFieldError }) => {
        setLoading(true);
        await handleSubmit(values, colorField).then((res) => {
            openSnackbar("success", res.data.message)
            setLoading(false);
            navigate("/products");
        }).catch((e) => {
            if (e.response.status == 422) {
                Object.entries(e.response.data.result).forEach(
                    ([key, value]) => setFieldError(key, value[0])

                );

            }
            setLoading(false);
        });
    };

    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
    });

    return (
        <div>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={state}
            >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <form onSubmit={handleSubmit}>

                        <Grid container spacing={6}>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>

                                <TextField
                                    type="text"
                                    name="english_title"
                                    id="english_title"
                                    value={values.english_title || ""}
                                    onChange={handleChange}
                                    label="Title In English"
                                    helperText={touched.english_title && errors.english_title}
                                    error={Boolean(touched.english_title && errors.english_title)}
                                    fullWidth
                                />

                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="text"
                                    name="arabic_title"
                                    id="arabic_title"
                                    value={values.arabic_title}
                                    onChange={handleChange}
                                    helperText={touched.arabic_title && errors.arabic_title}
                                    error={Boolean(touched.arabic_title && errors.arabic_title)}
                                    label="Title In Arabic"
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="text"
                                    name="english_desc"
                                    id="english_desc"
                                    value={values.english_desc}
                                    onChange={handleChange}
                                    helperText={touched.english_desc && errors.english_desc}
                                    error={Boolean(touched.english_desc && errors.english_desc)}
                                    label="Description In English"
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <TextField
                                    type="text"
                                    name="arabic_desc"
                                    id="arabic_desc"
                                    value={values.arabic_desc}
                                    onChange={handleChange}
                                    helperText={touched.arabic_desc && errors.arabic_desc}
                                    error={Boolean(touched.arabic_desc && errors.arabic_desc)}
                                    label="Description In Arabic"
                                    multiline
                                    rows={4}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                <Autocomplete
                                    options={subCategories}
                                    disableClearable
                                    defaultValue={state.category}
                                    getOptionLabel={(option) => option.english_name}
                                    onChange={(event, newValue) => {
                                        setFieldValue('category_id', newValue.id);
                                    }}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    id="controllable-category-state"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sub Category Name"
                                            name="category"
                                            value={state.category || ""}
                                            variant="outlined"
                                            onChange={handleChange}
                                            helperText={touched.category_id && errors.category_id}
                                            error={Boolean(touched.category_id && errors.category_id)}
                                            fullWidth
                                        />
                                    )}
                                />

                            </Grid>


                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ mb: 2 }}
                            >
                                <Typography sx={{ ml: 2 }} variant="h3"> Color </Typography>
                                <Button onClick={handleAddField} color="success" variant="contained">Add Color</Button>
                            </Grid>
                            {colorField.map((item, index) => {
                                let selectedColor = color.filter(function(item){
                                    return item.english_name==state.color
                                })
                                return(
                                    <SubCard content={true} title={state.color} sx={{ mt: 2 }}>
                                        <Grid container spacing={6}>
                                            <Grid item lg={10} md={10} sm={12} xs={12} sx={{ mt: 2 }}>
                                                <Autocomplete
                                                    options={color}
                                                    disableClearable
                                                    defaultValue={selectedColor[0]}
                                                    getOptionLabel={(option) => option.english_name}
                                                    onChange={(event, newValue) => {
                                                        handleColorChange(index, newValue);
                                                    }}
                                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                                    id="controllable-color-state"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Color Name"
                                                            name="color"
                                                            value={state.color || ""}
                                                            variant="outlined"
                                                            onChange={(e) => handleColorChange(index, e.target.value)}
                                                            helperText={touched.color_id && errors.color_id}
                                                            error={Boolean(touched.color_id && errors.color_id)}
                                                            fullWidth
                                                        />
                                                    )}
                                                />

                                            </Grid>

                                            <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 2, float: 'left' }}>
                                                <Button onClick={() => handleRemoveField(index)} color="error" variant="contained">Remove Color</Button>
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                <FileInput
                                                    index={index}
                                                    url={values.image}
                                                    handleChange={handleFileChange}
                                                    fileName="image"
                                                    accept=".jpeg , .png , jpg"
                                                    label="image"
                                                    helperText={touched.image && errors.image}
                                                    error={Boolean(touched.image && errors.image)}
                                                />
                                            </Grid>

                                            <Grid item lg={6} md={6} sm={12} xs={12} sx={{ mt: 2 }}>
                                                <LoadingButton
                                                    loading={loading}
                                                    color="secondary"
                                                    component="label"
                                                    variant="contained"
                                                    startIcon={<CloudUploadIcon />}
                                                >
                                                    Upload USDZ file
                                                    <VisuallyHiddenInput
                                                        onChange={(e) => handleFileUpload(index, e.target.files[0])}
                                                        type="file"
                                                        accept=".usdz"
                                                    />
                                                </LoadingButton>
                                                {item.file !== '' ? (
                                                    <span style={{ display: "flex", alignItems: "center", marginTop: 5, color: "#00e676" }}>
                                                        <span>File selected</span>
                                                        <span>&#10004;</span>
                                                    </span>) : (<></>)}
                                            </Grid>
                                        </Grid>
                                    </SubCard>
                                )})}


                        </Box>

                        <LoadingButton
                            type="submit"
                            color="primary"
                            loading={loading}
                            variant="contained"
                            sx={{ my: 4 }}
                        >
                            Submit
                        </LoadingButton>
                    </form>
                )}
            </Formik>
        </div>
    );
};

export default ProductForm;
